<template>
  <v-img
    id="login"
    class="login-img"
    max-height="100vh"
    :src="require(`@/assets/images/pages/background.jpg`)"
    alt="login-img"
  >
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card
          class="auth-card"
          color="#000000b5"
        >
          <!-- logo -->
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link
              to="/"
              class="d-flex align-center"
            >
              <v-img
                :src="appLogo"
                max-height="55px"
                max-width="55px"
                alt="logo"
                contain
                class="me-3 "
              ></v-img>

              <h2
                class="text-2xl font-weight-semibold"
                style="color: white !important; font-family: 'Orbitron';"
              >
                {{ appName }}
              </h2>
            </router-link>
          </v-card-title>

          <!-- title -->
          <v-card-text>
            <p class="text-center text-2xl font-weight-semibold white--text mb-2">
              {{ t('login.welcome_to_platform') }}
            </p>
            <p class="mb-2 white--text text-center">
              {{ t('login.message_platform') }}
            </p>
          </v-card-text>

          <!-- login form -->
          <v-card-text>
            <v-form
              ref="loginForm"
              @submit.prevent="loginSubmit"
            >
              <v-text-field
                v-model="email"
                outlined
                :label="t('login.username')"
                :rules="[validators.required]"
                hide-details="auto"
                :error-messages="errorMessages.email"
                class="mb-3 white--text lg-theme"
              ></v-text-field>
              <v-text-field
                v-model="password"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                :label="t('login.password')"
                :error-messages="errorMessages.password"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                :rules="[validators.required]"
                hide-details="auto"
                class="white--text lg-theme"
                @click:append="isPasswordVisible = !isPasswordVisible"
              >
              </v-text-field>
              <div class="d-flex align-center justify-space-between flex-wrap">
                <router-link
                  :to="{ name: 'auth-forgot-password' }"
                  class="mt-1 white--text"
                >
                  {{ t('login.forgot_password') }}
                </router-link>
                <div class="white--text">
                  v{{ appVersion }}
                </div>
              </div>

              <v-btn
                block
                color="secondary"
                type="submit"
                class="mt-6"
                :disabled="!email || !password"
              >
                {{ t('login.login') }}
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-img>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance, onMounted } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import { useRouter } from '@core/utils'
import { error } from '@core/utils/toasted'
import themeConfig from '@themeConfig'
import store from '@/store'
import { useUtils } from '@core/libs/i18n'

export default {
  setup() {
    const loginForm = ref(null)

    const { t } = useUtils()

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const email = ref(null)
    const password = ref(null)

    // const email = ref('admin')
    // const password = ref('123456')

    const errorMessages = ref([])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()
      if (!isFormValid) return

      /*
        1. Make HTTP request to get accessToken
        2. Store received token in localStorage for future use
        3. Make another HTTP request for getting user information
        4. On successful response of user information redirect to home page

        ? We have use promise chaining to get user data from access token
        ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
      */

      axios
        .post('/auth/login', { email: email.value, password: password.value })
        .then(response => {
          const { accessToken } = response.data

          // ? Set access token in localStorage so axios interceptor can use it
          // Axios Interceptors: https://github.com/axios/axios#interceptors
          localStorage.setItem('accessToken', accessToken)

          return response
        })
        .then(() => {
          axios.get('/auth/me').then(response => {
            const { user } = response.data
            const { ability: userAbility } = user

            // Set user ability
            // ? https://casl.js.org/v5/en/guide/intro#update-rules
            vm.$ability.update(userAbility)

            // Set user's ability in localStorage for Access Control
            localStorage.setItem('userAbility', JSON.stringify(userAbility))

            // We will store `userAbility` in localStorage separate from userData
            // Hence, we are just removing it from user object
            delete user.ability

            // Set user's data in localStorage for UI/Other purpose
            localStorage.setItem('userData', JSON.stringify(user))

            // On success redirect to home
            router.push('/')
          })
        })
        .catch(e => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to login!')
          error(e.response.message)
        })
    }

    const loginSubmit = () => {
      const userAbility = [
        {
          action: 'manage',
          subject: 'all',
        },
      ]

      // Set user ability
      // ? https://casl.js.org/v5/en/guide/intro#update-rules
      vm.$ability.update(userAbility)

      store.dispatch('auth/loginAction', { username: email.value, password: password.value })
    }

    onMounted(() => {
      store.dispatch('auth/logoutAction')

      // const { reauth } = route.value.params
      // if (reauth) window.location.reload()
    })

    return {
      loginForm,
      isPasswordVisible,
      email,
      password,
      socialLink,
      errorMessages,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      appVersion: themeConfig.app.version,
      appLogoBase64: themeConfig.app.logoBase64,

      handleFormSubmit,
      loginSubmit,
      t,

      validators: {
        required,
        emailValidator,
      },

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
<style lang="scss" scope>
div.v-image.v-responsive.login-img {
  div.v-image__image {
    background-size: cover;
  }
}

.lg-theme.theme--light.v-input input,
.lg-theme.theme--light.v-input textarea,
.lg-theme.theme--light.v-input label,
.lg-theme.theme--light.v-input button {
  color: rgba(255, 255, 255, 0.87);
}
.lg-theme.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: rgb(255 255 255 / 68%);
}
.lg-theme.theme--light.v-label {
  color: rgb(255 255 255 / 68%);
}
.lg-theme.theme--light.v-icon {
  color: rgb(255 255 255 / 68%);
}

@media only screen and (max-width: 600px) {
  .auth-wrapper.auth-v1 {
    align-items: flex-start;
  }
}
</style>
